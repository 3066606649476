import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { SectionWrap } from './client-logo-area.style'
import SwiperSlider from '../../../components/ui/swiper'
import ClientLogo from '../../../components/ui/client-logo'
import { Container, Row, Col } from '../../../components/ui/wrapper'

export const ClientLogoArea = ({ slider, sliderStyle }) => {
    const clientData = useStaticQuery(graphql`
        query ClientQuery {
            ourSolutionsJson(id: {eq: "clients"}) {
                clients {
                    id
                    image {
                        childImageSharp {
                            fluid(
                                fit: OUTSIDE
                                maxWidth: 230
                                maxHeight: 230
                                quality: 100
                            ) {
                                ...GatsbyImageSharpFluid_withWebp
                                presentationHeight
                                presentationWidth
                            }
                        }
                    }
                }
            }
        }
    `)
    const secData = clientData.ourSolutionsJson
    const clients = secData.clients

    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SwiperSlider settings={slider} {...sliderStyle}>
                            {clients.map((slide, i) => {
                                return (
                                    <div key={i} className="item">
                                        <ClientLogo
                                            brandImage={slide.image.childImageSharp}
                                            layout={3}
                                        />
                                    </div>
                                )
                            })}
                        </SwiperSlider>
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

ClientLogoArea.propTypes = {
    slider: PropTypes.object,
}

ClientLogoArea.defaultProps = {
    slider: {
        slidesPerView: 6,
        loop: false,
        //centeredSlides: true,
        speed: 1000,
        spaceBetween: 30,
        autoplay: {
            delay: 2500,
        },
        breakpoints: {
            320: {
                slidesPerView: 2,
            },
            575: {
                slidesPerView: 3,
            },
            767: {
                slidesPerView: 4,
            },
            991: {
                slidesPerView: 5,
            },
            1499: {
                slidesPerView: 6,
            },
        },
    },
    sliderStyle: {
        align: 'center',
    },
}
