import React from 'react'
import { useIntl } from 'gatsby-plugin-react-intl'
import SEO from '../components/seo'
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-custom'
import Footer from '../containers/layout/footer/footer-custom'
import PageHeader from '../components/pageheader'
import { OptionsArea, ClientLogoArea } from '../containers/our-solutions'
import ContactFormArea from '../containers/contact-form-area'
import CounterThree from '../containers/elements/counters/section-three'

const OurSolutionsPage = ({ pageContext, location }) => {
    const intl = useIntl()
    const t = id => intl.formatMessage({ id: id })

    return (
        <Layout location={location}>
            <SEO
                title={intl.formatMessage({
                    id: 'page.ourSolutions.title',
                })}
            />
            <Header />
            <PageHeader
                pageContext={pageContext}
                location={location}
                title={intl.formatMessage({
                    id: 'page.ourSolutions.title',
                })}
            />
            <OptionsArea />
            <CounterThree
                counter0={21}
                text0={t('page.ourSolutions.counter.forums')}
                counter1={154333}
                text1={t('page.ourSolutions.counter.members')}
                counter2={3086119}
                text2={t('page.ourSolutions.counter.pageviews')}
                counter3={14739307}
                text3={t('page.ourSolutions.counter.adImpressions')}
                counter4={92913}
                text4={t('page.ourSolutions.counter.newsletter')}
            />
            <ClientLogoArea />
            <ContactFormArea
                titleID="page.ourSolutions.contact.title"
                descID="page.ourSolutions.contact.desc"
            />
            <Footer />
        </Layout>
    )
}

export default OurSolutionsPage
