import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-react-intl'
import SectionTitle from '../../../components/ui/section-title'
import BoxImage from '../../../components/box-image/layout-four'
import { Container, Row, Col } from '../../../components/ui/wrapper'
import { SectionWrap } from './options-area.style'

export const OptionsArea = ({ sectionTitleStyle }) => {
    const intl = useIntl();
    const optionsData = useStaticQuery(graphql`
        query OptionsQuery {
            ourSolutionsJson(id: {eq: "options"}) {
                options {
                    id
                    icon {
                        fa
                    }
                }
            }
        }
    `)
    const secData = optionsData.ourSolutionsJson
    const options = secData.options

    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col xl={12}>
                        <SectionTitle
                            {...sectionTitleStyle}
                            title={intl.formatMessage({
								id: 'page.ourSolutions.options.title',
							})}
                        />
                    </Col>
                </Row>
                <Row>
                    {options.map(option => {
                        return (
                            <Col
                                lg={4}
                                md={6}
                                className="box-item"
                                key={`box-image-${option.id}`}
                            >
                                <BoxImage
                                    title={intl.formatMessage({
                                        id: `page.ourSolutions.options.${option.id}.title`,
                                    })}
                                    desc={intl.formatMessage({
                                        id: `page.ourSolutions.options.${option.id}.desc`,
                                    })}
                                    faIcon={option.icon.fa}
                                />
                            </Col>
                        )
                    })}
                </Row>
            </Container>
        </SectionWrap>
    )
}

OptionsArea.propTypes = {
    sectionTitleStyle: PropTypes.object,
}

OptionsArea.defaultProps = {
    sectionTitleStyle: {
        mb: '30px',
        responsive: {
            medium: {
                mb: '17px',
            },
        },
    },
}
